<template>
  <div
    :style="{
      height: deviceHeight
    }"
  >
    <slot name="header">
      <client-only>
        <BaseHeader v-if="!isInWechat() && !isInQQApp()" />
      </client-only>
    </slot>

    <slot name="main">
      <div
        id="activity-container"
        class="overflow-y-auto"
        :style="{
          height: `calc(100% - ${layoutTop})`,
          paddingTop: `${layoutTop}`
        }"
      >
        <slot />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import Cookies from 'js-cookie'
import { getVantNavBarHeight, disableZoom } from '~/utils'
import { useSharedStore } from '@/stores/shared'
import { isInWechat, isInQQApp, isiOSVersionAbove15 } from '@/utils/device'

const sharedStore = useSharedStore()
const customLayoutProps = useAttrs()
const deviceHeight = ref('100vh')

// 更改页面标题
useHead({
  title: customLayoutProps.title || 'C5GAME直播嘉年华'
})

const layoutTop = computed(() => {
  if (isInWechat() || isInQQApp() || customLayoutProps['hide-header']) {
    return '0px'
  } else {
    return `${px2rem(46) + px2rem(sharedStore?.C5ToolBarHeight || 0)}rem`
  }
})

onMounted(async () => {
  await nextTick()

  const ua = navigator.userAgent

  sharedStore.setHeaderHeight(getVantNavBarHeight())
  sharedStore.setC5ToolBarHeight(Number(Cookies.get('C5ToolBarHeight') || 0))

  native?.hiddenToolBar?.()
  disableZoom()

  if (isInApp) {
    deviceHeight.value = '100vh'
    return
  }

  // safari
  if (isiPhoneSafari()) {
    // ios15 及以上
    if (isiOSVersionAbove15()) {
      deviceHeight.value = '89vh'
    } else {
      deviceHeight.value = '85vh'
    }
  } else if (isMobile() && isAndroidDevice() && !isInQQ() && !isInWechat()) {
    // 安卓浏览器
    if (/HeyTapBrowser/i.test(ua)) {
      // 欢太浏览器 - oppo
      deviceHeight.value = `${px2rem(window.document.documentElement.clientHeight)}rem`
      return
    }
    if (/VivoBrowser/i.test(ua)) {
      // vivo
      deviceHeight.value = 'calc(100vh - 50px)'
      return
    }
    if (/MiuiBrowser/i.test(ua)) {
      // 小米
      deviceHeight.value = `${px2rem(window.document.documentElement.clientHeight - 55)}rem`
      return
    }
    deviceHeight.value = 'calc(100vh - 50px)'
  } else {
    // pc端 || 第三方app
    deviceHeight.value = '100vh'
  }
})
</script>

<style lang="scss">
html,
.bg-white {
  background-color: $color-grey-732;
}

@for $i from 0 through 10 {
  .f#{$i*1 + 12} {
    font-size: 1px * $i + 12 !important;
  }

  .mt#{$i*2} {
    margin-top: 2px * $i !important;
  }
  .mr#{$i*2} {
    margin-right: 2px * $i !important;
  }
  .mb#{$i*2} {
    margin-bottom: 2px * $i !important;
  }
  .ml#{$i*2} {
    margin-left: 2px * $i !important;
  }

  .mt#{$i*5} {
    margin-top: 5px * $i !important;
  }
  .mr#{$i*5} {
    margin-right: 5px * $i !important;
  }
  .mb#{$i*5} {
    margin-bottom: 5px * $i !important;
  }
  .ml#{$i*5} {
    margin-left: 5px * $i !important;
  }

  .pt#{$i*2} {
    padding-top: 2px * $i !important;
  }
  .pr#{$i*2} {
    padding-right: 2px * $i !important;
  }
  .pb#{$i*2} {
    padding-bottom: 2px * $i !important;
  }
  .pl#{$i*2} {
    padding-left: 2px * $i !important;
  }

  .pt#{$i*5} {
    padding-top: 5px * $i !important;
  }
  .pr#{$i*5} {
    padding-right: 5px * $i !important;
  }
  .pb#{$i*5} {
    padding-bottom: 5px * $i !important;
  }
  .pl#{$i*5} {
    padding-left: 5px * $i !important;
  }
}
</style>
